import React from 'react';
import { ReactComponent as ExclamationCircleIcon } from 'assets/icons/exclamation-circle.svg';

import { Col, Row } from 'antd';
import { messages } from '../../service/utils.js';

import Button from 'library/Button/Button.jsx';

const MissingInfo = ({ onSubmit, onClose }) => {
  return (
    <div className="container">
      <Row wrap={false}>
        <Col>
          <ExclamationCircleIcon className='icon' />
        </Col>
        <Col>
          <div className="title">{messages.t('missing_info.title')}</div>
          <div className="description">{messages.t('missing_info.description')}</div>

          <div className="buttons">
            <Button className="continueButton" onClick={onSubmit} type="default">
              {messages.t('missing_info.continue_anyway')}
            </Button>
            <Button type="primary" colorScheme="orange" onClick={onClose}>
              {messages.t('missing_info.go_back')}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MissingInfo;
