import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useFormik } from 'formik';
import PhoneInput from 'components/phone-input/PhoneInput';
import { Input, Form, Upload, message } from 'antd';
import { PlusOutlined, DeleteTwoTone } from '@ant-design/icons';

import useUpload from 'components/useUpload';
import useColumns from './useColumns';
import useUpdateUser from './useUpdateUser';
import useGetPetOwner from './useGetPetOwner';
import { messages } from 'service/utils';
import './styles.css';
import AddressAutocomplete from 'components/address-autocomplete/AddressAutocomplete';
import ButtonWithIcon from 'components/button-with-icon/ButtonWithIcon';
import CustomerTable from './customers-table/CustomerTable';
import NavigationButtons from './navigation-buttons/NavigationButtons';
import { PropertyType } from '../../constants/enums.js';

import { ReactComponent as HomeIcon } from 'assets/icons/home-icon.svg';
import { ReactComponent as HomeDarkIcon } from 'assets/icons/home-dark.svg';
import { ReactComponent as BuildingIcon } from 'assets/icons/building-white.svg';
import { ReactComponent as BuildingDarkIcon } from 'assets/icons/building-dark.svg';

const EditPetOwner = () => {
  const { uid } = useParams();
  const history = useHistory();
  const { petOwnerData, cardList, subscriptions, refetchPetOwner } = useGetPetOwner(uid);
  const { subscriptionsColumn, cardColumn } = useColumns(refetchPetOwner);
  const { updateUser } = useUpdateUser();
  const { uploadFile } = useUpload();
  const [imageUrl, setImageUrl] = useState('');
  const [address, setAddress] = useState('');
  const user = petOwnerData?.user;
  const [propertyType, setPropertyType] = useState(null);

  const formik = useFormik({
    initialValues: {
      avatarUrl: user?.avatarUrl || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || undefined,
      phonePrefix: user?.phonePrefix || '',
      phoneNumber: user?.phoneNumber || undefined,
      email: user?.email || undefined,
      address: user?.address
        ? {
            city: user?.address?.city,
            lat: user?.address?.lat,
            lng: user?.address?.lng,
            street: user?.address?.street,
            description: user?.address?.description,
            countryCode: user?.address?.country?.code,
            zipCode: user?.address?.zipCode,
            houseNumber: user?.address?.houseNumber,
            propertyType: user?.address?.propertyType
          }
        : undefined,
      floor: user?.address?.floor || undefined,
      apartment: user?.address?.apartment || undefined
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      updateUser(values, user.uid).then(() => {
        message.info(messages.t('users.update_success'));
      });
    }
  });

  const serializePropertyType = {
    PrivateHome: 'private_home',
    Apartment: 'apartment'
  };

  useEffect(() => {
    if (user?.uid) {
      setPropertyType(
        serializePropertyType[user?.address?.propertyType] || serializePropertyType.PrivateHome
      );
    }
  }, [user]);

  const removePhoto = () => {
    setImageUrl('');
  };

  const handleAddressChange = (area) => {
    formik.setFieldValue('address', {
      city: area.city,
      countryCode: area.countryCode,
      street: area.street,
      description: area.description,
      zipCode: area.zipCode,
      lat: area.lat,
      lng: area.lng,
      houseNumber: area.houseNumber ? +area.houseNumber : null
    });
  };

  useEffect(() => {
    setAddress(user?.address?.description);
  }, [user?.address]);

  useEffect(() => {
    setImageUrl(user?.avatarUrl);
  }, [user?.avatarUrl]);

  return (
    <Form onFinish={formik.handleSubmit}>
      <div className="vet-data">
        <div className="upload">
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={false}
            action={(file) => {
              uploadFile(file).then((res) => {
                formik.setFieldValue('avatarUrl', res);
                setImageUrl(res);
              });
            }}>
            {imageUrl ? (
              <div className="image-wrapper">
                <img src={imageUrl} alt="avatar" className="avatarUrl" />
                <DeleteTwoTone className="delete-icon" onClick={removePhoto} />
              </div>
            ) : (
              <div>
                <PlusOutlined /> <div style={{ marginTop: 8 }}>{messages.t('vet.upload')}</div>{' '}
              </div>
            )}
          </Upload>
        </div>
        <div className="inputs-wrapper">
          <div className="flex">
            <Input
              placeholder={messages.t('vet.first_name')}
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              required
            />
            <Input
              placeholder={messages.t('vet.last_name')}
              name="lastName"
              value={formik.values.lastName}
              onChange={(event) =>
                formik.setFieldValue(
                  'lastName',
                  event.target.value === '' ? undefined : event.target.value
                )
              }
            />
          </div>
          <div className="flex">
            <div className="flex-1">
              <Input
                placeholder={messages.t('vet.email')}
                name="email"
                value={formik.values.email}
                onChange={(event) =>
                  formik.setFieldValue(
                    'email',
                    event.target.value === '' ? undefined : event.target.value
                  )
                }
              />
            </div>
            <div className="flex-1">
              <PhoneInput
                handleChange={(event) =>
                  formik.setFieldValue(
                    'phoneNumber',
                    event.target.value === '' ? undefined : event.target.value
                  )
                }
                setFieldValue={formik.setFieldValue}
                phonePrefix={formik.values.phonePrefix}
                phoneNumber={formik.values.phoneNumber}
              />
            </div>
          </div>

          <AddressAutocomplete
            placeholder={messages.t('users.address')}
            onSelect={handleAddressChange}
            onChange={setAddress}
            address={address}
          />
          <div className="flex property-btn-wrapper">
            <ButtonWithIcon
              size="big"
              text="Private house"
              active={propertyType === PropertyType.PrivateHome}
              onClick={() => {
                setPropertyType(PropertyType.PrivateHome);
                formik.setFieldValue('address.propertyType', serializePropertyType.PrivateHome);
              }}
              icon={propertyType === PropertyType.PrivateHome ? <HomeIcon /> : <HomeDarkIcon />}
            />
            <ButtonWithIcon
              size="medium"
              text="Apartment"
              active={propertyType === PropertyType.Apartment}
              onClick={() => {
                setPropertyType(PropertyType.Apartment);
                formik.setFieldValue('address.propertyType', serializePropertyType.Apartment);
              }}
              icon={
                propertyType === PropertyType.Apartment ? <BuildingIcon /> : <BuildingDarkIcon />
              }
            />
          </div>

          {propertyType === PropertyType.Apartment && (
            <div className="flex">
              <Input
                placeholder={messages.t('users.floor')}
                name="floor"
                value={formik.values.floor}
                onChange={(e) => {
                  formik.setFieldValue('floor', parseInt(e.target.value));
                }}
                required={propertyType === PropertyType.Apartment}
              />
              <Input
                placeholder={messages.t('users.apartment')}
                name="apartment"
                value={formik.values.apartment}
                onChange={(e) => {
                  formik.setFieldValue('apartment', e.target.value);
                }}
                required={propertyType === PropertyType.Apartment}
              />
            </div>
          )}
        </div>
      </div>

      <CustomerTable
        columns={subscriptionsColumn}
        dataSource={subscriptions}
        heading={messages.t('totals.active_subscriptions')}
      />

      {/*<CustomerTable*/}
      {/*  columns={cardColumn}*/}
      {/*  dataSource={cardList}*/}
      {/*  heading={messages.t('users.cards')}*/}
      {/*/>*/}

      <NavigationButtons />
    </Form>
  );
};

export default EditPetOwner;
